// 
let animations = require('./animations');
animations();

let menus = require('./menus');
menus();

let searchBar = require('./search_bar.js');
searchBar();

let slider = require('./slider');
slider();


// layouts
let base = require('./layouts/__base');
base();
let faqs = require('./layouts/faqs');
faqs();